import axios from 'axios';
import { API_URL_AUTH } from '../../constants';

export interface IUserProfile {
  sname: string;
  mname: string;
  fname: string;
}

export class User {
  public profile: IUserProfile | null = null;
  public isProfileLoaded = false;

  constructor(public token: string | null = null) {
    this.token = token;
  }

  async loadProfile() {
    if (!this.token) {
      this.profile = null;
      this.isProfileLoaded = true;
      return;
    }

    const { data } = await axios.get(API_URL_AUTH + `/api/lk/auth/me`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    this.profile = data;
    this.isProfileLoaded = true;
  }

  get fullName(): string {
    if (!this.profile) {
      return '';
    }
    return `${this.profile.fname} ${this.profile.mname ? `${this.profile.mname} ` : ''}${this.profile.sname}`;
  }
}
