import * as React from 'react';
import { Loader } from '../Loader/Loader';
import { User } from './User';
import axios, { AxiosError } from 'axios';
import { API_URL_AUTH } from '../../constants';

interface IAuthProviderState {
  status: 'initialize' | 'pending' | 'success' | 'error';
  auth_error: AxiosError | null;
  user: User | null;
}

export interface IAuthContext extends IAuthProviderState {
  login: any;
  logout: any;
}

export const LOCALSTORAGE_TOKEN_KEY = `token`;

async function getLocalUser() {
  const user = new User(localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
  await user.loadProfile();
  return user;
}

const login = async (email: string, password: string) => {
  const { data } = await axios.post(API_URL_AUTH + `/api/lk/auth/login`, {
    login: email,
    password,
  });
  localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, data.access_token);
};

const logout = async () => {
  localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
};

const AuthContext = React.createContext<IAuthContext>({
  user: new User(),
  login,
  logout,
  status: 'initialize',
  auth_error: null,
});

export function AuthProvider(props: any) {
  const [state, setState] = React.useState<IAuthProviderState>({
    status: 'initialize',
    auth_error: null,
    user: null,
  });
  React.useEffect(() => {
    getLocalUser()
      .then((user) => setState({ status: 'success', auth_error: null, user }))
      .catch((auth_error) => setState({ status: 'error', auth_error, user: null }));
  }, []);

  const effectiveLogin = async (email: string, password: string) => {
    try {
      setState({ status: 'pending', auth_error: null, user: null });
      await login(email, password);
      const user = await getLocalUser();
      setState({ status: 'success', auth_error: null, user });
    } catch (auth_error) {
      setState({ status: 'error', auth_error, user: null });
    }
  };

  const effectiveLogout = async () => {
    try {
      setState({ status: 'pending', auth_error: null, user: null });
      await logout();
      setState({ status: 'success', auth_error: null, user: null });
    } catch (auth_error) {
      setState({ status: 'error', auth_error, user: null });
    }
  };

  if (state.status === 'initialize') {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        auth_error: state.auth_error,
        auth_status: state.status,
        login: effectiveLogin,
        logout: effectiveLogout,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => React.useContext(AuthContext);
