import axios from 'axios';
import { LOCALSTORAGE_TOKEN_KEY } from '../Auth/AuthContext';
import { API_URL_RESCORE } from '../../constants';

export const BackendClient = axios.create({
  baseURL: API_URL_RESCORE,
});

BackendClient.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${localStorage.getItem(LOCALSTORAGE_TOKEN_KEY)}`;
  return config;
});
