import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { FetchGraphSchemaSuccessAction } from '../actions/FetchGraphSchemaSuccessAction';
import { FetchGraphSchemaFailureAction } from '../actions/FetchGraphSchemaFailureAction';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class FetchGraphSchemaEpicProvider extends SwitchMapEpicProvider<FetchGraphSchemaStartAction> {
  public type = GraphViewActionTypes.FETCH_GRAPH_SCHEMA_START;

  protected async process(action: FetchGraphSchemaStartAction) {
    try {
      const { data } = await BackendClient.get(`/graph/schema/${action.schemaId}`);
      console.log(data);
      return new FetchGraphSchemaSuccessAction(data);
    } catch (e) {
      return new FetchGraphSchemaFailureAction(e);
    }
  }
}
