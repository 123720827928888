import { Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { share, switchMap } from 'rxjs/operators';
import {
  fetchPlaygroundQueryFailedCreator,
  fetchPlaygroundQuerySuccessCreator,
} from '../actions/PlaygroundActionCreators';
import PlaygroundActionTypes from '../actions/PlaygroundActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

const delay = (delayMs: number) => new Promise((resolve) => setTimeout(resolve, delayMs));

const getResult = async (requestId: string, tryNumber: number = 1): Promise<any> => {
  try {
    const { data } = await BackendClient.get(`/graph/result/${requestId}`);
    if (data.ready) {
      return data.result;
    }
  } catch (e) {}
  const delayMs = 200 + Math.min(tryNumber * 100, 1500);
  await delay(delayMs);
  return await getResult(requestId, tryNumber + 1);
};

const play = async (schemaId: string, graphId: string, input: object) => {
  const url = `/graph/schema/${schemaId}/graph/${graphId}/pass`;
  const body = {
    data: input,
  };
  try {
    const {
      data: { requestId },
    } = await BackendClient.post(url, body);
    const response = await getResult(requestId);
    return fetchPlaygroundQuerySuccessCreator(graphId, response);
  } catch (error) {
    return fetchPlaygroundQueryFailedCreator(graphId, error);
  }
};

const playEpic: Epic<AnyAction, any, any, AnyAction> = (action$) => {
  return action$.pipe(
    ofType(PlaygroundActionTypes.FETCH_PLAYGROUND_QUERY_START),
    switchMap((action) => play(action.schemaId, action.graphId, action.input)),
    share(),
  );
};

export default playEpic;
