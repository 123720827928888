export enum GraphViewActionTypes {
  FETCH_GRAPH_LIST_START = "FETCH_GRAPH_LIST_START",
  FETCH_GRAPH_LIST_SUCCESS = "FETCH_GRAPH_LIST_SUCCESS",
  FETCH_GRAPH_LIST_FAILURE = "FETCH_GRAPH_LIST_FAILURE",
  FETCH_GRAPH_SCHEMA_START = "FETCH_GRAPH_SCHEMA_START",
  FETCH_GRAPH_SCHEMA_SUCCESS = "FETCH_GRAPH_SCHEMA_SUCCESS",
  FETCH_GRAPH_SCHEMA_FAILURE = "FETCH_GRAPH_SCHEMA_FAILURE",
  CHANGE_LAYOUT_TYPE = "CHANGE_LAYOUT_TYPE",
  GRAPH_NODE_POSITION_CHANGE_START = "GRAPH_NODE_POSITION_CHANGE_START",
  GRAPH_NODE_POSITION_CHANGE_SUCCESS = "GRAPH_NODE_POSITION_CHANGE_SUCCESS",
  GRAPH_NODE_POSITION_CHANGE_FAILURE = "GRAPH_NODE_POSITION_CHANGE_FAILURE",
  GRAPH_NODE_PROPERTIES_UPDATE_START = "GRAPH_NODE_PROPERTIES_UPDATE_START",
  GRAPH_NODE_PROPERTIES_UPDATE_SUCCESS = "GRAPH_NODE_PROPERTIES_UPDATE_SUCCESS",
  GRAPH_NODE_PROPERTIES_UPDATE_FAILURE = "GRAPH_NODE_PROPERTIES_UPDATE_FAILURE",
  GRAPH_NODE_CREATION_START = "GRAPH_NODE_CREATION_START",
  GRAPH_EDGE_CREATION_START = "GRAPH_EDGE_CREATION_START",
  GRAPH_CREATION_START = "GRAPH_CREATION_START",
  GRAPH_EDGE_REMOVAL_START = "GRAPH_EDGE_REMOVAL_START",
  GRAPH_NODE_REMOVAL_START = "GRAPH_NODE_REMOVAL_START",
  GRAPH_REMOVAL_START = "GRAPH_REMOVAL_START",
  HTTP_REQUEST_FAILURE = "HTTP_REQUEST_FAILURE",
}