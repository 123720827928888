import { API_URL_RESCORE } from '../../constants';
import { GraphNodePropertiesChangeStartAction } from '../actions/GraphNodePropertiesChangeStartAction';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class UpdateNodePropertiesEpicProvider extends SwitchMapEpicProvider<GraphNodePropertiesChangeStartAction> {
  public type = GraphViewActionTypes.GRAPH_NODE_PROPERTIES_UPDATE_START;

  protected async process(action: GraphNodePropertiesChangeStartAction) {
    try {
      const url = `${API_URL_RESCORE}/graph/schema/${action.schemaId}/graph/${action.graphId}/node/${action.nodeId}/properties`;
      await BackendClient.post(url, action.properties);
    } catch (e) {}
    //return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
