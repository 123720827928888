import { SwitchMapEpicProvider } from '../../shared/SwitchMapEpicProvider`';
import { FetchGraphSchemaStartAction } from '../actions/FetchGraphSchemaStartAction';
import { CreateGraphAction } from '../actions/CreateGraphAction';
import { GraphViewActionTypes } from '../actions/GraphViewActionTypes.enum';
import { BackendClient } from '../../shared/BackendClient/BackendClient';

export class CreateGraphEpicProvider extends SwitchMapEpicProvider<CreateGraphAction> {
  public type = GraphViewActionTypes.GRAPH_CREATION_START;

  protected async process(action: CreateGraphAction) {
    try {
      const url = `/graph/schema/${action.schemaId}/graph`;
      const body = {
        title: action.title,
      };
      await BackendClient.post(url, body);
    } catch (e) {}

    return new FetchGraphSchemaStartAction(action.schemaId);
  }
}
