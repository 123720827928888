import { combineEpics } from 'redux-observable';
import { CreateEdgeEpicProvider } from './epics/CreateEdgeEpicProvider';
import { CreateGraphEpicProvider } from './epics/CreateGraphEpicProvider';
import { CreateNodeEpicProvider } from './epics/CreateNodeEpicProvider';
import { FetchGraphListEpicProvider } from './epics/FetchGraphListEpicProvider';
import { RemoveEdgeEpicProvider } from './epics/RemoveEdgeEpicProvider';
import { RemoveGraphEpicProvider } from './epics/RemoveGraphEpicProvider';
import { RemoveNodeEpicProvider } from './epics/RemoveNodeEpicProvider';
import { UpdateNodePositionEpicProvider } from './epics/UpdateNodePositionEpicProvider';
import { FetchGraphSchemaEpicProvider } from './epics/FetchGraphSchemaEpicProvider';
import { UpdateNodePropertiesEpicProvider } from './epics/UpdateNodePropertiesEpicProvider';

export const GRAPH_VIEW_EPICS = combineEpics(
  new CreateEdgeEpicProvider().toEpic(),
  new CreateGraphEpicProvider().toEpic(),
  new CreateNodeEpicProvider().toEpic(),
  new FetchGraphListEpicProvider().toEpic(),
  new RemoveEdgeEpicProvider().toEpic(),
  new RemoveGraphEpicProvider().toEpic(),
  new RemoveNodeEpicProvider().toEpic(),
  new UpdateNodePositionEpicProvider().toEpic(),
  new UpdateNodePropertiesEpicProvider().toEpic(),
  new FetchGraphSchemaEpicProvider().toEpic(),
);
